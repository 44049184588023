import React, { useState } from 'react';
import Skill from '../components/Skill';
import reactIcon from '../assets/icons/react.png';
import reduxIcon from '../assets/icons/redux.png';
import jQueryIcon from '../assets/icons/jquery.png';
import nodeIcon from '../assets/icons/node.svg';
import expressIcon from '../assets/icons/express.png';
import mysqlIcon from '../assets/icons/mysql.png';
import mongodbIcon from '../assets/icons/mongodb.svg';
import awsIcon from '../assets/icons/aws.png';
import ubuntuIcon from '../assets/icons/ubuntu.png';
import nginxIcon from '../assets/icons/nginx.svg';
import jestIcon from '../assets/icons/jest.png';
import postmanIcon from '../assets/icons/postman.svg';
import gitIcon from '../assets/icons/git.png';
import javascriptIcon from '../assets/icons/javascript.png';
import htmlIcon from '../assets/icons/html.png';
import sassIcon from '../assets/icons/sass.png';
import linuxIcon from '../assets/icons/command-line.png';
import apiIcon from '../assets/icons/api.png';


const frontend = [
  { name: 'React', icon: reactIcon, proficiency: 4 },
  { name: 'Redux', icon: reduxIcon, proficiency: 2 },
  { name: 'HTML', icon: htmlIcon, proficiency: 5 },
  { name: 'CSS/SCSS', icon: sassIcon, proficiency: 5 },
  { name: 'Javascript', icon: javascriptIcon, proficiency: 4 },
  { name: 'jQuery', icon: jQueryIcon, proficiency: 3 },
];

const backend = [
  { name: 'Node', icon: nodeIcon, proficiency: 4 },
  { name: 'Express', icon: expressIcon, proficiency: 4 },
  { name: 'mySQL', icon: mysqlIcon, proficiency: 3 },
  { name: 'mongoDB', icon: mongodbIcon, proficiency: 2 },
];

const serverAndHosting = [
  { name: 'AWS (EC2 instances)', icon: awsIcon, proficiency: 1 },
  { name: 'Ubuntu', icon: ubuntuIcon, proficiency: 2 },
  { name: 'Linux Command Line', icon: linuxIcon, proficiency: 2 },
  { name: 'Nginx', icon: nginxIcon, proficiency: 2 },
];

const toolsAndTechnology = [
  { name: 'RESTful APIs', icon: apiIcon, proficiency: 3 },
  { name: 'Jest', icon: jestIcon, proficiency: 2 },
  { name: 'Postman', icon: postmanIcon, proficiency: 2 },
  { name: 'Git', icon: gitIcon, proficiency: 3 },
];

export default function Skills() {
  const [selectedSkillDomain, setSelectedSkillDomain] = useState(frontend);

  const handleSkillDomainClick = (skillDomain) => {
    setSelectedSkillDomain(skillDomain);
  };

  return (
    <section className="skills">
      <div className="skill-domain-menu">
        <ul>
          <li onClick={() => handleSkillDomainClick(frontend)} className={`btn secondary-btn ${selectedSkillDomain === frontend ? 'selected' : ''}`}>Frontend</li>
          <li onClick={() => handleSkillDomainClick(backend)} className={`btn secondary-btn ${selectedSkillDomain === backend ? 'selected' : ''}`}>Backend</li>
          <li onClick={() => handleSkillDomainClick(serverAndHosting)} className={`btn secondary-btn ${selectedSkillDomain === serverAndHosting ? 'selected' : ''}`}>Server and Hosting</li>
          <li onClick={() => handleSkillDomainClick(toolsAndTechnology)} className={`btn secondary-btn ${selectedSkillDomain === toolsAndTechnology ? 'selected' : ''}`}>Tools & Technologies</li>
         </ul>
      </div>
      <div className="skills-listing">
        {selectedSkillDomain.map((skill, index) => (
          <Skill key={skill.name} skillName={skill.name} icon={skill.icon} proficiency={skill.proficiency} animationOffset={index * 100}/>
        ))}
      </div>
    </section>
  );
}
