import React from 'react';
import Hero from '../components/Hero';

export default function Landing() {
  return (
    <section className="landing-container">
      <div className="landing-overlay"> </div>
      <Hero />
    </section>
  );
}
