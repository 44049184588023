import React from 'react';
import Landing from '../containers/Landing';
import Skills from '../containers/Skills';
import Profile from '../containers/Profile';
import Projects from '../containers/Projects';

export default function Home() {
  return (
    <main>
      <Landing />
      <Profile />
      <Skills />
      <Projects />
    </main>
  );
}
