import React from 'react';

export default function Project({ info }) {
  return (
    <div className="project-container">
      <div className="project-feature">
        <div className="project-feature-content">
          <h3>{info.name}</h3>
          <h4 className="info-text">{info.subHeader}</h4>
          <div className="project-btn-container">
            <a
              href={info.website}
              target="_blank"
              rel="noreferrer"
              className="btn cta-btn"
            >
              Explore site
            </a>
            <a
              href={info.github}
              target="_blank"
              rel="noreferrer"
              className="btn secondary-btn"
            >
              View code
            </a>
          </div>
        </div>
        <img src={info.screenshot} alt={`${info.name} website display`} className="screenshot" />
      </div>
      <div className="project-skills">
        <div className="project-skill-group">
          <h6>Hosting:</h6>
          <p>{info.hosting}</p>
        </div>
        <div className="project-skill-group">
          <h6>Frontend:</h6>
          <p>{info.frontend}</p>
        </div>
        <div className="project-skill-group">
          <h6>Backend:</h6>
          <p>{info.backend}</p>
        </div>
        <div className="project-skill-group">
          <h6>Database:</h6>
          <p>{info.database}</p>
        </div>
        <div className="project-skill-group">
          <h6>Architecture:</h6>
          <p>{info.architecture}</p>
        </div>
      </div>
    </div>
  );
}
