import React from 'react'

export default function Hero() {
  return (
    <div className='hero'>
        <h1 className='info-text'>Hi, I'm James Wotherspoon</h1>
        <h2 className='hero-main hero-main-1'>Code enthusiast &</h2>
        <h2 className='hero-main hero-main-2'>Junior developer</h2>
        <a href="mailto:james.t.wotherspoon@gmail.com" className='btn cta-btn hero-cta-btn'>Get in touch</a>
    </div>
  )
}
