import React from 'react';
import profile from '../assets/images/profile.png';

export default function Profile() {
  return (
    <div className="profile">
      <h2>About Me</h2>
      <h4 className='sub-heading'>Enthusiastic and detail-oriented Junior Developer</h4>
      <div className="profile-summary">
        Enthusiastic and detail-oriented Junior Developer with a strong foundation in JavaScript, React, Node, Express,
        MySQL, and Git. Committed to continuous learning and dedicated to working diligently, I am enthusiastic about
        taking on challenges that will allow me to broaden my knowledge and skill set in the dynamic field of
        development. Ready to work hard and learn as much as possible to deliver high-quality solutions.
      </div>
    </div>
  );
}
