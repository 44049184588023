import React, {useState, useEffect} from 'react';

const useSlideAnimation = (offset) => {
  const [slideIn, setSlideIn] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSlideIn(true);
    }, offset);

    return () => clearTimeout(timeoutId);
  }, [offset]);

  return slideIn;
};

export default function Skill({ skillName, icon, proficiency, animationOffset }) {
  const slideIn = useSlideAnimation(animationOffset);

  return (
    <div className={`skill-container pre-slide ${slideIn ? 'slide-up' : ''}`}>
      <div className="skill-icon-container">
        <img className="skill-icon" src={icon} alt={skillName} />
      </div>
      <p className="skill-label">{skillName}</p>
      <div className="skill-proficiency-container"></div>
    </div>
  );
}
