import React from 'react';
import GitHubIcon from '../assets/icons/github.svg';
import LinkedInIcon from '../assets/icons/linkedin.svg';

export default function SocialIcons() {
  return (
    <div className="social-icons-container">
      <a href="https://github.com/JamesWotherspoon" target="_blank" rel="noreferrer">
      <img src={GitHubIcon} alt="github" className="social-icon" />
      </a>
      <a href="https://www.linkedin.com/in/james-wotherspoon-956914175/" target="_blank" rel="noreferrer">
      <img src={LinkedInIcon} alt="linkedin" className="social-icon" />
      </a>
    </div>
  );
}
