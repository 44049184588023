import React from 'react';
import Project from '../components/Project';
import oikoScreenshot from '../assets/images/oiko-screenshot.png';

const oiko = {
  name: 'oiko',
  subHeader: 'Money management platform',
  website: 'https://www.oiko.jameswotherspoon.com/auth',
  github: 'https://github.com/JamesWotherspoon/oiko-server',
  screenshot: oikoScreenshot,
  hosting: 'AWS EC2 Ubuntu instance, Nginx, PM2, SSL certificate',
  frontend: 'React, Redux, Mui-materials, SCSS, Axios',
  backend: 'Node.js, Express.js, JSON web tokens',
  database: 'MySQL, Sequelize',
  architecture: 'Layered client + server architecture, RESTful and RPC APIs',
}

export default function Projects() {
  return (
    <section className="projects">
      <h2>Projects</h2>
      <h4 className="sub-heading">Check out the source code on GitHub or explore the live site</h4>
      <Project info={oiko} />
      <div className='more-projects'>
        <h2>Explore more Projects on my <a href="https://github.com/JamesWotherspoon">github</a></h2>
      </div>
    </section>
  );
}
